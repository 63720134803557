#loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6 {
      font-size: 30px;
      font-weight: 500;
    }

    .loading {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .loading div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 15px;
      background: var(--color-primary) ;
      animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .loading div:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }
    .loading div:nth-child(2) {
      left: 28px;
      animation-delay: -0.12s;
    }
    .loading div:nth-child(3) {
      left: 48px;
      animation-delay: 0;
    }
    @keyframes loading {
      0% {
        top: 8px;
        height: 68px;
      }
      50%, 100% {
        top: 24px;
        height: 32px;
      }
    }
  }
}

