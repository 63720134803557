.title {
  font-size: 2rem;
  font-weight: 800;
  color: var(--color-text-title);
  position: relative;

  // &::after {
  //     content: '';
  //     display: block;
  //     width: 115px;
  //     height: 3px;
  //     border-radius: 5px;
  //     background-color: var(--color-primary);
  //     // position: absolute;
  //     bottom: -15px;
  //     margin: auto;
  // }
}

.paragraph {
  color: var(--color-text-read);
  font-size: 20px;
}

.text-center {
  text-align: center;
}

.link-cta {
  font-size: 18px;
  color: #1a73e8;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
}

.accent-color {
  color: var(--color-primary);
}

.accent-background {
  background: var(--color-primary);
}