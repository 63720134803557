
@import "./global/styles/flex.scss";
@import "./global/styles/typography.scss";
@import "./global/styles/spaces.scss";

// glass-effect {
//   background-color: #ffffff10;
//   backdrop-filter: blur(12px);
//   -webkit-backdrop-filter: blur(12px);
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-background);
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  height: 30px;
  border-radius: 30px;
}

html {
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden !important;
  margin: 0;
  // font-family: "Poppins", sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-prev {
  z-index: 5 !important;
  background: #000 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  left: 20px !important;
}

.slick-next {
  z-index: 5 !important;
  background: #000 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  right: 20px !important;
}

@media (max-width: 420px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}

.svg-animate {
  animation: items-svg 4s infinite ease-in;
  z-index: 9;
  position: absolute;
}

@keyframes items-svg {
  0% {
  }
  50% {
    transform: translate(0, 30px);
  }
  50% {
    transform: translate(0, -30px);
  }
}

:root {
  --color-primary: #0a64a8;
  --color-primary-dark: #06185e;
  --color-primary-gradient-90: linear-gradient(90deg, #0a64a8 0%, #06185e 100%);
  --color-primary-gradient-reverse-90: linear-gradient(
    90deg,
    #0a64a8 80%,
    #3ea2e5 100%
  );
  --color-primary-gradient-180: linear-gradient(180deg, #0a64a8, #90caf9);
  --color-primary700: #0a64a8;
  --color-primary400: #93c1f9;
  --color-secondary: #04bf58;
  --color-primary-light: #f0f9ff;
  --color-primary-light-detail: #daf0ff;
  
  --color-text-title: #121212;
  // --color-text-title: #3b3b3b;
  --color-text-subtitle: #4f4f4f;
  --color-text-read: #62707e;
  --color-background: #fff;

  --font-default: "Montserrat", sans-serif;
}
