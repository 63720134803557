.mt-0 {
  margin-top: 0 !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-20 {
  margin-top: 20px;
}

.ph-20 {
  padding: 0 20px;
}

.hr {
  margin: 100px auto 0;
  background: var(--color-primary400);
  width: 100%;
  max-width: 600px;
  min-width: 400px;
  height: 1.5px;
  border: none;

  @media (max-width: 440px) {
    min-width: 240px;
    width: 80%;
  }
}
